export const set = "set$";
export const brandName = ""; // slogan

// 开发环境默认配置

// let _baseURL = `https://zzb-openapi-abroadqa.dragonest.com`

let baseUrl = "";

let hostname = document.location.hostname;
console.log("hostname=" + location.hostname);

switch (hostname) {
  case "luengate-pre.dragonest.com": // 国内预发布服
    baseUrl = "//pre-website-api.dragonest.com";
    break;
  case "luengate-qa.dragonest.com": // 国内测试服
    baseUrl = "//qa-website-api.dragonest.com";
    break;
  case "luen.ac.dragonest.com": // 国内正式服
    baseUrl = "//website-api.dragonest.com";
    break;
  default:
    baseUrl = "//qa-website-api.dragonest.com";
    break;
}

export default baseUrl;
