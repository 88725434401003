import axios from 'axios';
import baseUrl from './config.js';
let getJsonUrl=()=>{
   return axios.get(`${baseUrl}/resources?appcode=e8ed09899f3&lang=zh-cn&menu_mark=key`)
}


export const getData= async()=>{
 let res = await getJsonUrl()
 let jsonUrl = res.data.data.list[0].fields_data
 let axios_arr = Object.values(jsonUrl).map((item)=>{
    return axios.get(item)
 })
 return   axios.all(axios_arr)
//   .then(axios.spread(function (acct, perms) {
//     // 两个请求现在都执行完成
//   }));
} 


export const getCityInfo =(resource_code)=>{
   return axios.get(`${baseUrl}/resource?appcode=e8ed09899f3&lang=zh-cn&resource_code=${resource_code}`)
}

