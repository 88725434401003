import {areaLine} from './areaLine'

// 加载资源列表

const racePath = '/images/races/';
const heroPath = '/images/heroIcon/';
const cityPath = '/images/cities/';
const cityCheckedPath = '/images/city_checked/';

export const manifest_ = [
  { src: "https://static.ilongyuan.cn/official_website/e8ed09899f3/16105efd1b431eebd80d68f7b7ecb66c.jpg", id: "map" },
  { src: "/images/y1.png", id: "y1" },
  { src: "/images/y2.png", id: "y2" },
  { src: "/images/cityname-bg.png", id: "cityName" },
  { src: heroPath + "heroBg.png", id: "heroBg" },
];



//areaHtmlIndex是导航索引
export const cityData = [
  {
    id: 'city0',
    x: 1356,
    y: 890,
    w: 77,
    h: 62,
    heroId: 'cityHero0',
    // raceName: "灵族",
    raceId:'race0',
    cityName: '阿特莫里之心',
    areaHtmlIndex: 0,
    cityInfo: '苍白的阿特莫里之心是一片由白色大理石铺成的荒芜之地。空地四周伫立着伴有精美符文的石柱，这些石柱巧妙地围成了一种图案，据知晓其中含义的人称，这些符文预言了不久后灵族的苏醒。',
    imgs: ["cityImg1.png"]
  },
  {
    x: 2473,
    y: 540,
    w: 51,
    h: 39,
    id: 'city1',
// raceName: "龙族",
    heroId: 'cityHero1',
    raceId:'race1',
    cityName: '古龙坟墓',
    areaHtmlIndex: 1,
    cityInfo: '古龙坟墓隐藏在龙脉山的群峰之间，所有妄图涉足之人在活着进入墓穴之前，就已葬身于碎岩尖石之中。龙族的脑子里存放着墓地的唯一地图，唯有在将死之际，通往坟墓的道路才会在他们的眼前慢慢浮现。',
    imgs: [{
      img:'xxx'
      
    }]
  },
  {
    x: 2485,
    y: 878,
    w: 57,
    h: 54,
    id: 'city2',
    heroId: 'cityHero2',
    raceId:'race2',
    cityIcon: "city2.png",
    cityName: '神羽城',
    areaHtmlIndex: 2,
    cityInfo: '神羽城是光羽族最为骄傲的杰作，这座和大自然充分结合的城市洋溢着朝朝不息的旺盛活力。城市建筑与自然融合紧密，多为树洞中的居所、在树上建立的树屋以及空中修建的依靠树木悬挂的树屋群等。城市中心的神树支撑着光羽族的德鲁伊信仰。树荫下的子民们也细心地使用魔法守护着整片森林。光羽族每年都会在神树上举办祭祀大典来纪念和维持这奇妙的共生关系。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2570,
    y: 1170,
    w: 70,
    h: 83,
    id: 'city3',
    heroId: 'cityHero3',
    raceId:'race3',
    // raceName: "人族",
    raceIcon:'race3.png',
    cityName: '望归城',
    areaHtmlIndex: 3,
    cityInfo: '长期的流亡生涯不断地打磨着人类的种族品质，其中最坚韧不屈的一群人在落圣山下建立了第二帝国。在神族的庇护下，这群流亡者蓄势待发，必将在未来的某个时刻重新夺回昔日的荣耀。',
    imgs: ["cityImg1.png"]
  },{
    x: 3043,
    y: 1569,
    w: 75,
    h: 61,
    id: 'city4',
    raceId:'race4',
    heroId: 'cityHero4',
    // raceName: "海族",
    cityIcon: "city4.png",
    cityName: '德雷斯',
    areaHtmlIndex: 4,
    cityInfo: '深渊内海的底部，德雷斯城围绕着巨大深渊修建而成。因为海族酷爱绚彩的癖好，整座城市被打造成永夜深海中的不灭明灯。其中最为富丽堂皇，最为璀璨夺目的，是德雷斯城那号称深渊明珠的皇城宫殿。那奔流不息的多彩水流，犹如水下若隐若现的缤纷彩虹般萦绕在黄金灿灿的宫殿穹顶之上。从王宫中央高高伸出的，是一座造型精美的廊桥。廊桥通往深渊之上的圣殿，从圣殿中可以观察漆黑深渊中的一举一动。',
    imgs: ["cityImg1.png"]
  },{
    x: 2831,
    y: 759,
    w: 61,
    h: 67,
    id: 'city5',
    raceId:'race5',
    heroId: 'cityHero5',
    // raceName: "冰川族",
    cityIcon: "city5.png",
    cityName: '雪村',
    areaHtmlIndex: 5,
    cityInfo: '高尔克山峰永不停息的风雪之中，雪村在一块巨石的庇护下成为了天然的避风港。冰川族内最有才华的诗人曾在诗中将他的家乡称为风雪息止之地，那里生长着香脆甜美的雪兰果实；狂风暴雪中是山神创造的纯净的无魔空间，只有对他心怀敬畏的人才能活着看到风雪后的湛蓝天空。',
    imgs: ["cityImg1.png"]
  }, {
    x: 3021,
    y: 953,
    w: 64,
    h: 85,
    id: 'city6',
    raceId:'race6',
    heroId: 'cityHero6',
    raceName: "洞洞族",
    cityIcon: "city6.png",
    cityName: '千洞城',
    areaHtmlIndex: 6,
    cityInfo: '千洞城被分为旧城区与新城区。旧城区由无人居住的废弃城市和如迷宫般蜿蜒曲折的洞穴组成，其独特的地理优势成为了洞洞族重要的防御要塞。新城区是一座立体的城市，以遗迹广场为中心修建，自下而上的贯通了高尔克山的地底与峰峦。旧城区与新城区只在最底下的母神祭坛相连。洞洞族在这里召唤地下精灵，祭祀星球母神。纯朴善良的他们坚信地下精灵是母神的使者。靠着坚定纯粹的信仰，洞洞族世代在高尔克山内繁衍生息。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2686,
    y: 1378,
    w: 86,
    h: 88,
    id: 'city7',
    heroId: 'cityHero7',
    raceId:'race7',
    // raceName: "神族",
    cityIcon: "city7.png",
    cityName: '神都',
    areaHtmlIndex: 7,
    cityInfo: '一半在天上一半在地上的神族城市。上城区为缺口环形浮空岛，环形的圆心处有一块巨大的水晶，白天水晶的光芒会让下城区处于上城区的阴影之中，水晶的光芒柔和而美丽，在整个神都平原上都可见。下城区为六边形城市，围绕着坚实的城墙，六个脚上分别有一个浮空水晶，用魔法支撑着上城区的漂浮。神都是个综合的大型城市，是整个大陆的文化中心。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2299,
    y: 1510,
    w: 36,
    h: 40,
    id: 'city8',
    raceId:'race8',
    // raceName: "矮人族",
    cityIcon: "city8.png",
    heroId: 'cityHero8',
    cityName: '都林',
    areaHtmlIndex: 8,
    cityInfo: '经历过大迁徙的矮人在进入圣山之后便着手建立都林，这座矿业之都日后成为了整个大陆上最长的城市。在柴油动力为主的新时代，矮人们对城区进行了大刀阔斧的改造，新的都林像是一艘吞吐着滚滚油烟货船，在布满金银财宝的的航线上一往无前。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2255,
    y: 1807,
    w: 58,
    h: 54,
    id: 'city9',
    raceId:'race9',
    // raceName: "兽族",
    cityIcon: "city9.png",
    heroId: 'cityHero9',
    cityName: '路易斯集市',
    areaHtmlIndex: 9,
    cityInfo: '森林将路易斯驯服，也将他抚养长大。成年后的路易斯在此建立路易斯集市，把秩序带回到曾一度混乱无主的森林。集市之中，你可以在任何时间交易任何货物，不用计较货物的来源与去向，交易的安全程度让大陆上其他市场望尘莫及。前提是不要触及他们的底线---集市内禁止动用武力。',
    imgs: ["cityImg1.png"]
  }, {
    x: 3210,
    y: 594,
    w: 34,
    h: 36.6,
    id: 'city10',
    raceId:'race10',
    // raceName: "光羽族",
    cityIcon: "city10.png",
    heroId: 'cityHero10',
    cityName: '隐血神树',
    areaHtmlIndex: 10,
    cityInfo: '隐血神树终年茂盛，每过十年血红的花朵会开满在隐血神树的枝头。光羽中的血羽族人常年守护着隐血神树，可悲的是，在残酷的战争洗礼下，很少有血羽能活着见证两次隐血神树的花期。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2798,
    y: 1825,
    w: 79,
    h: 60.5,
    id: 'city11',
    raceId:'race11',
    // raceName: "哥布林",
    cityIcon: "city11.png",
    heroId: 'cityHero11',
    cityName: '丰饶港',
    areaHtmlIndex: 11,
    cityInfo: '与隔海相望的大锤港不同，丰饶港繁荣的原因主要来源于当地发达的鱼获业。哥布林族利用其发达科技来满足西格玛城庞大的鱼类食品需求。正如哥布林对科技追求到极致的贪婪，全自动化的捕鱼机器夜以继日的从海洋中攫取着宝贵的海洋资源。',
    imgs: ["cityImg1.png"]
  },{
    x: 3213,
    y: 1832,
    w: 68,
    h: 77,
    id: 'city12',
    raceIcon: "race1.png",
    raceId:'race11',
    raceName: "哥布林",
    cityIcon: "city12.png",
    heroId: 'cityHero17',
    cityName: '西格玛城',
    areaHtmlIndex: 11,
    cityInfo: '如果机械有生命，他一定就是西格玛城；如果机械有情感，他一定就是西格玛城；如果机械有神明，他一定就是西格玛城；西格玛城是哥布林们至高无上的智慧结晶。其运转原理之复杂艰深，就连最资深的哥布林工程师也无法讲清。西格玛是慈祥的，任何人都能在这里找到容身之处。西格玛也是残忍的，不熟悉规则的人随时都会被淘汰。',
    imgs: ["cityImg1.png"]
  },{
    x: 3226,
    y: 1509,
    w: 41,
    h: 43,
    id: 'city13',
    raceId:'race13',
    // raceName: "虫族",
    cityIcon: "city13.png",
    heroId: 'cityHero13',
    cityName: '孵化岩',
    areaHtmlIndex: 13,
    cityInfo: '昔日强大且统一的虫族如今溃不成军。只有远古之时最伟大的主母在白雾森林中修建的高耸巢穴提醒着世人曾经虫族的不可一世。虫族的骄傲杰作像一个遗世独立的孤塔，给人以畏惧的同时也将它的居民推向了绝路。',
    imgs: ["cityImg1.png"]
  }, {
    x: 3442,
    y: 1516,
    w: 50,
    h: 49,
    id: 'city14',
    raceId:'race14',
    // raceName: "基拉",
    cityIcon: "city14.png",
    heroId: 'cityHero14',
    cityName: '眷顾遗址',
    areaHtmlIndex: 13,
    cityInfo: '一座深埋在沼泽之下的城市遗址，偶尔残垣断壁的顶部会从水面显露出来，还能够看见面目全非的古迹上肆意生长的带有剧毒的植物。相传这些遗址中有着令人难以想象的奇珍异宝，从这些宝藏中可以窥见这座未知之城的昔日荣光。危险的沼泽成了这些宝藏的天然保护伞，不论盗贼还是考古学家都会在此望而却步，失落城市的神秘面纱埋藏在污浊之中至今无法解开。',
    imgs: ["cityImg1.png"]
  },{
    x: 3436,
    y: 1782,
    w: 70,
    h: 72,
    id: 'city15',
    raceId:'race15',
    // raceName: "恶魔",
    cityIcon: "city15.png",
    heroId: 'cityHero15',
    cityName: '卡达斯',
    areaHtmlIndex: 15,
    cityInfo: '一片巨大的幻影笼罩着整座城市，幻影之外只能看见一座阴森黑暗的城堡。走进这座黑暗城堡，欢迎来到卡达斯，一座灯红酒绿的不夜城。混沌教母是这里的主人，恶魔们在此编织梦境，客人们则寻求片刻欢愉。“契约至上”便是这里的法则，但只要你仔细瞧瞧，会发现这里根本没有法则。',
    imgs: ["cityImg1.png"]
  },{
    x: 3392,
    y: 1938,
    w: 48,
    h: 41,
    id: 'city16',
    raceId:'race16',
    // raceName: "不眠族",
    cityIcon: "city16.png",
    heroId: 'cityHero16',
    cityName: '回忆墓群',
    areaHtmlIndex: 15,
    cityInfo: '当不眠者从冰冷的坟墓中醒来，唯一能遵从的是被赋予的命令和求生本能。生前的记忆对这些活死人来说是毫无意义但又异常珍贵的东西。但首先要做的，是用这副腐烂身躯从迷宫中逃出“生”天。',
    imgs: ["cityImg1.png"]
  },{
    x: 1964,
    y: 964,
    w: 61,
    h: 50,
    id: 'city17',
    raceId:'race2',
    raceName: "光羽族",
    cityIcon: "city17.png",
    heroId: 'cityHero23',
    cityName: '落羽港',
    areaHtmlIndex: 2,
    cityInfo: '百年前的突发事件让光羽族付出了惨痛的代价。为了纪念，光羽族将此地命名为落羽港。今天的落羽港却呈现出与它的悲惨名字完全不同的光景。因其优异的地理环境，以及光羽族发展所必要的资源需求，落羽港已经成为大陆西部的三大港口之一。光羽族的交通工具非常先进，其中最为人所知的便是他们的快船。在无风无浪的天气，他们也可以通过风魔法符文制造动力。',
    imgs: ["cityImg1.png"]
  },{
    x: 1812,
    y: 1592,
    w: 56,
    h: 57,
    id: 'city18',
    raceId:'race12',
    // raceName: "人族",
    cityIcon: "city18.png",
    heroId: 'cityHero18',
    cityName: '多多城',
    areaHtmlIndex: 12,
    cityInfo: '受到那次灾难性的打击之后，人类被迫在世界各地流浪到现在。他们中的一群富有开拓精神的勇敢者偶然发现了多多岛这个远离大陆纷争的世外桃源。如今，多多城已从当年的小渔村迅速成长为一个独立的国家，并掌握着大陆贸易航线的重要航道。',
    imgs: ["cityImg1.png"]
  }, {
    x: 2670,
    y: 1793,
    w: 64,
    h: 62,
    id: 'city19',
    raceId:'race7',
    // raceName: "神族",
    cityIcon: "city19.png",
    heroId: 'cityHero19',
    cityName: '大锤港',
    areaHtmlIndex: 7,
    cityInfo: '大锤港是矮人与神族共同建立的贸易港，其内部被划分为矮人区和海港区两个各自具备独立的警备系统的区域。随着海港的日益繁荣，大锤港已然成为整个大陆的经济中心。但随之而来的，是发展中不可避免的环境污染，虽然其他种族对于矮人的这种做法深表不满，但在诱人的利益驱使之下，各种族间的矛盾只能被隐藏的越来越深。',
    imgs: ["cityImg1.png"]
  },{
    x: 3920,
    y: 2156,
    w: 51,
    h: 41,
    id: 'city20',
    raceId:'race15',
    // raceName: "不眠族",
    cityIcon: "city20.png",
    heroId: 'cityHero20',
    cityName: '遗忘之城',
    areaHtmlIndex: 15,
    cityInfo: '遗忘之城是荒芜活沙旅途的终点站，某种意义上也是名副其实的死亡之城。即使是那些侥幸逃脱活沙吞噬的人，在这里也会被虫爪撕碎，甚至来不及感受到一丝的痛苦。遗忘之城的国王潜伏在王座深处，平静的等待着任何胆敢闯入这里的人。',
    imgs: ["cityImg1.png"]
  }, {
    x: 3029,
    y: 1243,
    w: 126,
    h: 138,
    id: 'city21',
    raceId:'race7',
    // raceName: "神族",
    cityIcon: "city21.png",
    heroId: 'cityHero21',
    cityName: '长城',
    areaHtmlIndex: 7,
    cityInfo: '长城北起高尔克山，南接刀锋山与深渊内海，蜿蜒数百公里，高数百米，城墙内外风景迥异。内朝神都，临接千池与黑林，风景秀美。外朝冷原，寂静永夜令人胆寒。神都的光芒在这里迎来终点，洒落在城墙上，显得无比的神圣美丽。城下要塞位于长城的南北中心，与长城各处的烽火相连，是依靠城墙修建的石质堡垒。多数种族在此都有驻军，由战神统一管理。',
    imgs: ["cityImg1.png"]
  }
];

//ID为势力值

// for (var i = 0; i < areaLine.length; i++) {
//   manifest_.push({ src: '../images/' + "l" + i + ".png", id: "l" + i });
// }

// for (var i = 0; i < cityData.length; i++) {
//   manifest.push({ src: cityPath + "city" + i + ".png", id: "city" + i });
//   manifest.push({ src: cityCheckedPath + "city" + i + "_checked.png", id: "city" + i +"_checked"});
// }
// for(let key  in areaHeros){
//   areaHeros[key].forEach(ele => {
//     manifest.push({ src: heroPath + ele.icon, id:  ele.id});
//   });
// }




