import '../styles/style.css';

import React, { Component } from 'react'

import {getData,getCityInfo} from '../configs/axios.js'

// var Hammer = require('react-hammerjs');
import { manifest_} from '../data/data';


import $ from 'n-zepto';

require('imports-loader?this=>window!' + '../common/create-all.js');
require('../common/hammer.min.js');

import closeBtn from '../images/close_btn.png'
import splitImg from '../images/split_img.png'
import cityInfo_split from '../images/cityinfo_split.png'
import moreBtn from '../images/heroIcon.png'
import closeInfo from '../images/closeInfo.png'

let cityHerosSelf=[], raceHerosSelf=[],raceData =[],
areaLine=[],areaHeros =[],races = [], cities = [],
cityData=[],cityTales=[],manifest =[];

async function  initJson (){
    manifest.push(...manifest_)
   let res = await getData()
   cityData= res[0].data
   areaLine = res[1].data
   raceData = res[2].data
   areaHeros = res[3].data
   cityHerosSelf = res[4].data
   cityTales = res[5].data
   raceHerosSelf = res[6].data

   areaLine.forEach(item=>{
    manifest.push({
        id:item.id,
        src:item.borderline
        })
    })
   cityData.forEach(item=>{
    manifest.push({
        id:item.id,
        src:item.cityIcon
        })
    })
    raceData.forEach(item=>{
      manifest.push({
        id:item.id,
        src:item.raceIcon
        })
    })
    for(let key  in areaHeros){
    areaHeros[key].forEach(ele => {
        manifest.push({ src:  ele.icon, id:  ele.id});
    });
    }
   races = initMapData(raceData)
   cities = initMapData(cityData);
   loadAssest(manifest);
}
initJson(); 


function initMapData(arr){
    return arr.reduce((prev,cur,index,arr)=>{
        prev[cur.id] = cur;
        return prev;
    },{})
}


var tap = "ontouchstart" in window ? "tap" : "click";
var start = "ontouchstart" in window ? "touchstart" : "mousedown";
var end = "ontouchend" in window ? "touchend" : "mouseup";
var infoFlag = true;
var stage = null,
    // heroVoice=null,
    curCity = '',
    curIndex= '',
    queue = null,
    canvas = null,
    dpi = window.devicePixelRatio,
    w = document.body.clientWidth * dpi,
    h = document.body.clientHeight * dpi,

    containerAll = new createjs.Container(),
    containerZoom = new createjs.Container(),
    containerMap = new createjs.Container(),
    containerArea = new createjs.Container(),
    containerCity = new createjs.Container(),
    containerRace = new createjs.Container(),
    containerLine = new createjs.Container(),
    containerCloud = new createjs.Container(),

    loadCount = 2,
    rem = 1,
    zoom = 0,
    zoomMax = 0,
    heroName = 'area0',

    mapW = 5279,
    mapH = 2612,
    heroZoom = 1.55,
    pinchstart = { x: 0, y: 0 },
    mapChangData = { x: 0, y: 0, scale: 1 },
    currentTargetCity = null;

var bolUpdate = true,
    show = 0;
var mapData = [
    { id: "map", x: 0, y: 0, container: containerMap },
];

if( decodeURI(window.location.href)) var params =   decodeURI(window.location.href).split("?")[1]
if( params)  var cityId = params.split("=")[1]

function biginSet(bol){
    if(show==1){
        $(".loading").fadeOut(1000);
    }
    show=1;
    if(bol)biginSet=function(){};
    mapW=5279,
    mapH=2612,
    //w=w*dpi;
    //h=h*dpi;
    w=document.documentElement.clientWidth*dpi;
    h=document.documentElement.clientHeight*dpi;
    canvas.width=w;
    canvas.height =h;
    //text.text="w:"+w+" h:"+h+" width"+document.documentElement.clientWidth+" Height:"+document.documentElement.clientHeight;
    //canvas.style.width=w/dpi+"px";
    //canvas.style.height=h/dpi+"px";
    zoom=h/mapH;
    var k=1;
    if(w/mapW>zoom){
        zoom=w/mapW;
        k=1.14;
    }else{
        if(zoom/w*mapW<1.04){
            k=1.1;
        }else{
            if(zoom/w*mapW<1.06){
                k=1.068;
            }else{
                if(zoom/w*mapW<1.09){
                    k=1.055;
                }else{
                    if(zoom/w*mapW<1.1){
                        k=1.05;
                    }
                }
            }
        }
    }
    containerAll.y=mapChangData.y=-(zoom*mapH*k-h)/2;
    mapChangData.scale=containerAll.scaleX=containerAll.scaleY=k;
    mapW=mapW*zoom;
    mapH=mapH*zoom;
    zoomMax=1/zoom*1.4;
    zoomMax=Math.ceil(zoomMax*1000)/1000;
    containerZoom.setTransform(0,0,zoom,zoom,0,0,0,0,0);
    //英雄图标显示的比例
    // var width=w-589*rem*dpi;
    // // if(1664/858>width/h){
    // //     heroZoom=width/1664/zoom-0.006;
    // // }else{
    // //     heroZoom=h/1390/zoom-0.006;
    // // }
    // heroZoom=width/1664/zoom;
    // if(h/1390/zoom<heroZoom){
    //     heroZoom=h/1390/zoom;
    // }
    // heroZoom-=0.006;
    // console.log(222222)
    // if (typeof (startF) == 'function') {startF();show=2;console.log(253255454)}
    //startF||startF();
    //containerAll.mask = new createjs.Shape(new createjs.Graphics().drawRect(0, 0, mapW,mapH));  
}
var screenchange = {
    width: 1334,
    height: 750,
    resize: function (bol) {
        var html = document.documentElement;
        var width = html.clientWidth, height = html.clientHeight;
        if (width > height) {//横屏
            if (width / height > this.width / this.height) {
                rem = height / this.height
            } else {
                rem = width / this.width;
            }
            html.style.fontSize = rem * 100 + "px";
            //text.text="w:"+w+" h:"+h+" width"+document.documentElement.clientWidth+" Height:"+document.documentElement.clientHeight;
            biginSet(bol);
        }
    },
    set: null,
    fn: function () {
        var self = screenchange;
        clearTimeout(self.set);
        self.resize(true);
        self.set = setTimeout(function () {
            self.resize(true);
        }, 300);
    },
    init: function () {
        window.addEventListener("onorientationchange" in window ? "orientationchange" : "resize", this.fn, false);
        this.fn();
    }
}

function bitmapMap(obj, container) {

    var bitmap = new createjs.Bitmap(queue.getResult(obj.id));
    bitmap.x=obj.x;
    bitmap.y=obj.y;
    if(obj.w!=undefined){
      bitmap.scaleX=bitmap.scaleY=obj.w/bitmap.image.width;
    }
    container.addChild(bitmap);
}

function bitmapHeroIcon(arr,key, container) {
    var heroIconItem = new createjs.Container();
    heroIconItem.name = key;
    heroIconItem.visible = false;
    for(let i =0; i < arr.length;i++){
        let item = arr[i]
        var heroIcon = new createjs.Container();
        heroIcon.x = item.x;
        heroIcon.y = item.y;
        heroIcon.href = item.href;
        // heroIcon.camptype = obj.camptype;
        heroIcon.setTransform(item.x, item.y, .4, .4, 0, 0, 0, 10, 10);
        var bitmap = new createjs.Bitmap(queue.getResult(item.id));
        
        var bitmapBg = new createjs.Bitmap(queue.getResult('heroBg'));
        bitmapBg.scaleX = bitmapBg.scaleY = 1.35;
        bitmapBg.x = -24; bitmapBg.y = -24;
        heroIcon.addChild( bitmapBg,bitmap);

        heroIcon.addEventListener("click", function (e) {
            e.preventDefault();
            // window.location.href = e.currentTarget.href;
            window.open(e.currentTarget.href);
            // window.location.href = 'https://ac.dragonest.com/zh-cn/charactor/detail/e52b47afcfe'
        });

        heroIconItem.addChild(heroIcon)
    }
    
  
    container.addChild(heroIconItem);
    //stage.addChild(bitmap);
}

function switchQuality(quality){
    switch(quality){
        case 1:return 'quality1';break;
        case 2:return 'quality2';break;
        case 3:return 'quality3';break;
        case 4:return 'quality4';break;
        case 5:return 'quality5';break;
    }
}


//更多种族英雄
function moreRaceHero(hreoIconsArr, ele,obj,iscity) {
    let heroArr = hreoIconsArr;
    let name = obj.areaName;
    let info =  `<p>${obj.info}</p>`;
    if(hreoIconsArr.length > 5 ) {
        heroArr = hreoIconsArr.slice(0,5)
        heroArr.push({
            icon:'heroIcon.png',
        }) 
    }
    var heroStr = '<div class="heroImgWrap">'
    for(let i=0; i < heroArr.length; i++){
        if(i==5){
            heroStr+= `<img src=${moreBtn} id="moreHeroBtn" heroId='${obj['heroId']}' />`
        }else{
            heroStr+= `<img src="${heroArr[i].icon}" class="${switchQuality(heroArr[i].quality)}"/>`
        }
    }
    heroStr+=`</div>`
    var moreHeroStr = `<h5>${name}</h5>${info}${heroStr}`;
    var x = ((obj.x + 24) * zoom * mapChangData.scale + mapChangData.x) / dpi - 117 * rem;
    var y = ((obj.y + 20) * zoom * mapChangData.scale + mapChangData.y) / dpi - 117 * rem;
    
  
    ele.html(moreHeroStr).css({ "left": x + "px", "top": y + "px", "display": "block" });
}

//更多英雄
function moreCityHero(hreoIconsArr, ele,obj) {
    var div = $('<div>',{class:'city-hero-item',id:obj.id});
    let heroArr = hreoIconsArr;
    let name =obj.cityName;

    var heroStr = `<div class="heroImgWrap"><i class="triangle"></i>`
    for(let i=0; i < heroArr.length; i++){
        heroStr+= `<a href="${heroArr[i].href}" target="_blank"><img src="${heroArr[i].icon}"  heroId='${obj['heroId']}' class='${switchQuality(heroArr[i].quality)}'/></a>`
    }
    heroStr+=`</div>`
    var moreHeroStr = `<h5>${name}</h5>${heroStr}`;
    div.html(moreHeroStr)
    // ele.html(moreHeroStr);

    var x = ((obj.x + obj.w+5) * zoom * mapChangData.scale + mapChangData.x) / dpi;
    var y = ((obj.y + obj.h/2 - 35) * zoom * mapChangData.scale + mapChangData.y) / dpi;

    div.css({ "left": x + "px", "top": y + "px", "display": "block" });

    div.appendTo(ele);
}

function moreCityOtherHero(hreoIconsArr, ele) {
    $("#city-other-hero").css('display','block');
    var heroStr = ``;
    for(let i=0; i < hreoIconsArr.length; i++){
        let obj = hreoIconsArr[i]
        var x = ((obj.x) * zoom * mapChangData.scale + mapChangData.x) / dpi;
        var y = ((obj.y) * zoom * mapChangData.scale + mapChangData.y) / dpi;
        heroStr+= `<a href="${hreoIconsArr[i].href}" target="_blank" style="left:${x}px;top:${y}px">
        <img src="${hreoIconsArr[i].icon}"   class='${switchQuality(hreoIconsArr[i].quality)}' /><p class='hero-point'></p></a>`
    }

    ele.html(heroStr);
}

var indexNum = 0;
function bitmapRaceIcon(obj, index, container) {
    var bitmap = new createjs.Bitmap(queue.getResult("race" + index));
    var bitmapBounds = bitmap.getBounds();

    bitmap.setTransform(obj.x, obj.y, 1, 1, 0, 0, 0, 0, 0);
    bitmap.index = obj.areaHtmlIndex;
    var text = new createjs.Text(obj.name, "16px Arial", "#fef5e8");
    var bounds = text.getBounds();
 
    text.setTransform(obj.x - bounds.width*2.8/2 + bitmapBounds.width/2 , obj.y + 120, 2.8, 2.8, 0, 0, 0, 0, 0);
    container.addChild(bitmap, text);

    bitmap.addEventListener("click", function (e) {
        let cityIds = obj['cityId'].split(',');
        $('#race-hero').css('display','none')
        $('#city-hero').html('');
        let cityItem = containerCity.getChildByName(cityIds[0])
        currentTargetCity = cityItem;
        // cityChecked(currentTargetCity)
        currentTargetCity.getChildAt(3).color = '#ff9701';
        cityIconClickHandle(e.currentTarget.index,obj,true)
    });
 
    bitmap.addEventListener("mouseover", function (e) {
        containerArea.getChildAt(indexNum*2+1).color = '#fef5e8';
        containerArea.getChildAt(index*2+1).color = '#ff9701';
        containerArea.getChildAt(index*2).cursor = 'pointer'
        indexNum = index;
            setTimeout(function(){
                let hreoIconsArr = raceHerosSelf[obj['heroId']];
                moreRaceHero(hreoIconsArr,$('#race-hero'),obj,false)
            },100)

        var num = 0;
        $("#race-hero").delegate('#moreHeroBtn','click',function(){
            
            if(this.id == 'moreHeroBtn'){
                num++
                let hreoIconsArr = raceHerosSelf[obj['heroId']];
                let heroStr = ''
                let heroArr = [];
                
                for(let i = 0 ; i < hreoIconsArr.length ; i+=5){
                    heroArr.push([...hreoIconsArr.slice(i,i+5),{ icon:'heroIcon.png'}])
                }

                num = num <= heroArr.length-1 ? num  : 0;
                let total = heroArr[num].length
 
                for(let i=0; i < total; i++){
                    i == total-1 ?   
                    heroStr+= `<img src=${moreBtn} id="moreHeroBtn"/>`:
                    heroStr+= `<img src="${ heroArr[num][i].icon}" class="${switchQuality(heroArr[num][i].quality)}"/>`
                    
                }
                $(".heroImgWrap").html(heroStr)

            }
        })
    });

    bitmap.addEventListener("mouseout", function (e) {
        // containerArea.getChildAt(index*2+1).color = '#fef5e8';
        // $(".more-hero").css({ "display": "none" });
    });
}

//从 canvas 提取图片 image
function convertCanvasToImage(canvas) {
    //新Image对象，可以理解为DOM
    var image = new Image();
    // canvas.toDataURL 返回的是一串Base64编码的URL
    // 指定格式 PNG
    image.src = canvas.toDataURL("image/png");
    return image;
}

function bitmapCityIcon(obj, container) {
    var bitmapCanvas = document.createElement('canvas');
    bitmapCanvas.width = obj.w;
    bitmapCanvas.height = obj.h;

    var bitmapCtx = bitmapCanvas.getContext('2d');

    bitmapCtx.drawImage(queue.getResult(obj.id), 0, 0, obj.w, obj.h);
    var imageData = bitmapCtx.getImageData(0, 0, obj.w, obj.h);
    var length = imageData.data.length;
    for (var index = 0; index < length; index += 4) {
      var r = imageData.data[index];
      var g = imageData.data[index + 1];
      var b = imageData.data[index + 2];
      var alpha=imageData.data[index + 3];
      //这里可以对 r g b 进行计算（这里的rgb是每个像素块的rgb颜色）
   
      if(alpha != 0){
  
        imageData.data[index] = 255;
        imageData.data[index + 1] = 255;
        imageData.data[index + 2] = 255;
        imageData.data[index + 3] = 80;
      }
  
    }
    bitmapCtx.putImageData(imageData, 0, 0);

    var cityIcon = new createjs.Container();
    cityIcon.index = obj.areaHtmlIndex;
    cityIcon.name=obj.id;

    var bitmapChecked = new createjs.Bitmap(convertCanvasToImage(bitmapCanvas));
    // var bitmapChecked = new createjs.Bitmap(queue.getResult(obj.id+'_checked'));
    // var checkedBounds = bitmapChecked.getBounds();
    bitmapChecked.visible = false;
    // bitmapChecked.scaleX = obj.w/checkedBounds.width;
    // bitmapChecked.scaleY = obj.h/checkedBounds.height;

    var bitmap = new createjs.Bitmap(queue.getResult(obj.id));
    bitmap.name=obj.id

    var text = new createjs.Text(obj.cityName, "16px Arial", "#fef5e8");
    var bounds = text.getBounds();
  
    var bitmapBg = new createjs.Bitmap(queue.getResult('cityName'));

    var bgBounds = bitmapBg.getBounds();

    bitmapBg.x=-bgBounds.width/4 + obj.w/2;
    bitmapBg.y=obj.h+6;

    // text.x= obj.x;
    bitmapBg.scaleX=bitmapBg.scaleY=.6;
    text.x=obj.w/2-bounds.width/2;
    text.y=obj.h+12;

    cityIcon.addChild(bitmapBg,bitmap,bitmapChecked ,text);
    cityIcon.scaleX=cityIcon.scaleY=2;

    cityIcon.setTransform(obj.x , obj.y , 1, 1, 0, 0, 0, 0, 0);
    container.addChild(cityIcon);
    // cityIcon.style.cursor = 'pointer';
    cityIcon.addEventListener("click", function (e) {
        if(currentTargetCity && currentTargetCity.name !=obj.id)  {
            currentTargetCity.getChildAt(3).color = '#fef5e8';
            $('#'+currentTargetCity.name).css({border:null})
        };
        
        currentTargetCity = e.currentTarget;
        cityIconClickHandle(e.currentTarget.index,obj,false)
        currentTargetCity.getChildAt(3).color = '#ff9701';
     
    });
    cityIcon.addEventListener("mouseover", function (e) {

        e.currentTarget.getChildAt(2).cursor = 'pointer'
        e.currentTarget.getChildAt(2).visible = true;
    });
    cityIcon.addEventListener("mouseout", function (e) {
        e.currentTarget.getChildAt(2).visible = false;
        //  resetCityChecked();
    });
}

function resetCityChecked(){
    if(currentTargetCity){
        currentTargetCity.getChildAt(3).color = '#fef5e8';
        // currentTargetCity.getChildAt(2).visible = true;
        let bitmap1 = currentTargetCity.getChildAt(1);
        let bitmap2 =   currentTargetCity.getChildAt(2);
        let bitmapBounds = bitmap1.getBounds();
        let x =  bitmapBounds.width*0.1/2, y = bitmapBounds.height*0.1/2;
        createjs.Tween.get(bitmap1).to({ x: bitmap1.x+x, y: bitmap1.y+y, scaleX: 1, scaleY: 1}, 200);
        createjs.Tween.get(bitmap2).to({ x: bitmap2.x+x, y: bitmap2.y+y, scaleX: 1, scaleY: 1}, 200);
    }
}

function cityChecked(curCityParam){

    curCityParam.getChildAt(2).visible = true;
    // curCityParam.getChildAt(2).visible = false
    

    let bitmap1 =   curCityParam.getChildAt(1);
    let bitmap2 =   curCityParam.getChildAt(2);
    // let bitmapBounds = bitmap1.getBounds();
    // let x =  bitmapBounds.width*0.1/2, y = bitmapBounds.height*0.1/2;
    // createjs.Tween.get(bitmap1).to({ x: bitmap1.x-x, y: bitmap1.y-y, scaleX: 1.1, scaleY: 1.1}, 200);
    // createjs.Tween.get(bitmap2).to({ x: bitmap2.x-x, y: bitmap2.y-y, scaleX: 1.1, scaleY: 1.1}, 200);

}

function cityIconClickHandle(lineIndex,raceOrCityObj,isRace){
    let cityItem = isRace ? cities[raceOrCityObj['cityId'].split(',')[0]]  : raceOrCityObj;
    htmlCityCon(cityItem, false);
    curCity = cityItem
    if(curIndex === lineIndex && infoFlag) {
        $('#'+cityItem.id).css({border:'1px solid rgba(230,230,230,1)'})
        return;
    }

    let time = curIndex == lineIndex ? 10 : 350;
    if(containerLine.getChildAt(curIndex)) containerLine.getChildAt(curIndex).visible = false;
    $('#city-hero').html('');
    $("#city-other-hero").css('display',"none")
    let cityIds = isRace ? raceOrCityObj['cityId'] : races[raceOrCityObj['raceId']].cityId 
    heroName = "area" + lineIndex
    containerLine.getChildAt(lineIndex).visible = true;
    showArea(lineIndex, false);
    curIndex=lineIndex

    setTimeout(()=>{
        let otherHreoIcons = areaHeros[heroName];
        if(infoFlag){
            cityIds.split(',').forEach(item=>{
                let hreoIconsArr = cityHerosSelf[cities[item]['heroId']];
                moreCityHero(hreoIconsArr,$('#city-hero'),cities[item]);
            })
            if(otherHreoIcons) moreCityOtherHero(otherHreoIcons,$('#city-other-hero'))
        }else{
            let hreoIconsArr = cityHerosSelf[cities[cityItem.id]['heroId']];
            moreCityHero(hreoIconsArr,$('#city-hero'),cityItem);
        }
        $('#'+cityItem.id).css({border:'1px solid rgba(230,230,230,1)'})
    },time)
    
}
function bitmapLine(obj, i, container) {
    var bitmap = new createjs.Bitmap(queue.getResult(obj.id));
    bitmap.x = obj.x;
    bitmap.y = obj.y;
    bitmap.visible = false;

    container.addChild(bitmap);
}


function transformEnd(x, y, scale, bol) {
    if (bol == undefined) bol = false;
    ///scale
    if (scale < 1) {
        scale = 1;
    } else {
        if (scale > zoomMax) {
            x = -(zoomMax / scale * (pinchstart.x - x) - pinchstart.x);
            y = -(zoomMax / scale * (pinchstart.y - y) - pinchstart.y);
            scale = zoomMax;
            bol = false;
        }
    }
    mapChangData.scale = scale;
    scaleChang(scale);
    //x,y
    if (x > 0) {
        x = 0;
    } else {
        if (w > mapW * scale + x) {
            x = w - mapW * scale;
        }
    }
    if (y > 0) {
        y = 0;
    } else {
        if (h > mapH * scale + y) {
            y = h - mapH * scale;
        }
    }
    mapChangData.y = y;
    mapChangData.x = x;
    //createjs.Tween.get(containerAll).to({x:x,y:y,scaleX:scale,scaleY:scale},300);
    if (!bol) {
        createjs.Tween.get(containerAll).to({ x: x, y: y, scaleX: scale, scaleY: scale }, 300);
    } else {
        containerAll.x = x;
        containerAll.y = y;
        containerAll.scale = scale;
    }
}
function transform(x, y, scale) {
    ///scale
    if (scale < 1) {
        scale = 1;
    }
    containerAll.scaleX = containerAll.scaleY = scale;
    scaleChang(scale);
    //x,y
    if (x > 0) {
        x = 0;
    } else {
        if (w > mapW * scale + x) {
            x = w - mapW * scale;
        }
    }
    if (y > 0) {
        y = 0;
    } else {
        if (h > mapH * scale + y) {
            y = h - mapH * scale;
        }
    }
    containerAll.x = x;
    containerAll.y = y;

    //text.text=createjs.Ticker.getMeasuredFPS();
    stage.update();
    // document.getElementById("dd").innerHTML=createjs.Ticker.getMeasuredFPS();
}

function scaleChang(scale) {
    if (scale > 1.2) {
        containerArea.visible = false;
        containerCity.visible = true;
        containerRace.visible = true;
        // containerHero.visible = true;

    } else {
        containerArea.visible = true;
        // containerHero.visible = true;
        containerCity.visible = false;
        containerRace.visible = false;
    }
}

function panstart(evt) {
    hammertime.on('panmove', panmove);
    hammertime.on('panend', panend);
    // bigin = false;
}
function panmove(evt) {
    transform(mapChangData.x + evt.deltaX * dpi, mapChangData.y + evt.deltaY * dpi, mapChangData.scale);
}
function panend(evt) {
    hammertime.off('panmove', panmove);
    hammertime.off('panend', panend);
    // bigin = true;
    mapChangData.y = containerAll.y;
    mapChangData.x = containerAll.x;
}

function startF() {
    for(let i=0;i<mapData.length;i++){
        bitmapMap(mapData[i],mapData[i].container);
    }
    for (i = 0; i < raceData.length; i++) {
        bitmapRaceIcon(raceData[i], i, containerArea);
    }
    for (i = 0; i < cityData.length; i++) {
        bitmapCityIcon(cityData[i],containerCity);
    }
    for (i = 0; i < areaLine.length; i++) {
        bitmapLine(areaLine[i], i, containerLine);
    }

    containerArea.visible = false;
    containerCity.visible = false;
    containerRace.visible = false;

    //cloud();
    containerZoom.addChild
        (containerMap,containerLine, containerArea, containerCity,containerRace, containerCloud);

    containerAll.addChild(containerZoom);
    stage.addChild(containerAll);

    var cloudData = [
        { id: "y1", w: 3481, x: 960, y: 0, ax: -3481, speed: 0.5 },
        { id: "y1", w: 3481, x: -3481, y: 400, ax: -3481, speed: 0.5 },
        { id: "y2", w: 3843, x: 700, y: 300, ax: -3843, speed: 0.7 },
        { id: "y2", w: 3843, x: -3843, y: 700, ax: -3843, speed: 0.7 }
    ];
    
    for (var i = 0; i < cloudData.length; i++) {
        cloudAn(cloudData[i]);
    }

    createjs.Ticker.timingMode = createjs.Ticker.RAF;
    createjs.Ticker.addEventListener("tick", function (e) {
        for (var i = 0; i < 4; i++) {
            var obj = containerCloud.getChildAt(i);
            if (obj.x + cloudData[i].speed < 5279) {
                obj.x += cloudData[i].speed;
            } else {
                obj.x = cloudData[i].ax;
            }
        }
        stage.update();
        bolUpdate = !bolUpdate;
    });

}
// function cloudAn(obj){
//     var mapWidth=5279;
//     var bitmap = new createjs.Bitmap(queue.getResult(obj.id));
//     bitmap.x=obj.x;
//     bitmap.y=obj.y;
//     bitmap.scaleX=bitmap.scaleY=obj.w/bitmap.image.width;
//     var time=(mapWidth-bitmap.x)/obj.speed*35;

//     if(obj.wait==0){
//         createjs.Tween.get(bitmap)
//         .to({x: mapWidth}, time);
//     }else{
//         createjs.Tween.get(bitmap, {loop: true},true)
//         .wait(time*obj.wait)
//         .to({x: mapWidth}, time)
//     }
//     containerCloud.addChild(bitmap);
// }
// function cloud(){
//     var cloudData = [
//         {id:"y1",w:3481,x:960,y:0,speed:1,wait:0},
//         //{id:"y1",w:3481,x:-3481,y:0,speed:1,wait:0.05},
//         {id:"y1",w:3481,x:-3481,y:400,speed:1,wait:0.55},
//         {id:"y2",w:3843,x:700,y:300,speed:1.4,wait:0},
//         //{id:"y2",w:3843,x:-3843,y:300,speed:1.4,wait:0.05},
//         {id:"y2",w:3843,x:-3843,y:700,speed:1.4,wait:0.55}
//     ];
//     for(var i=0;i<cloudData.length;i++){
//         cloudAn(cloudData[i]);
//     }
//     // var bitmap = new createjs.Bitmap(queue.getResult("ybig"));
//     // bitmap.scaleX=bitmap.scaleY=mapWidth/bitmap.image.width;
//     //containerZoom.addChild(bitmap);
// }
function cloudAn(obj) {
    var bitmap = new createjs.Bitmap(queue.getResult(obj.id));
    bitmap.x = obj.x;
    bitmap.y = obj.y;
    bitmap.scaleX = bitmap.scaleY = obj.w / bitmap.image.width;
    containerCloud.addChild(bitmap);
}
function cloud() {
    var cloudData = [
        { id: "y1", w: 3481, x: 960, y: 0, speed: 1, wait: 0 },
        //{id:"y1",w:3481,x:-3481,y:0,speed:1,wait:0.05},
        { id: "y1", w: 3481, x: -3481, y: 400, speed: 1, wait: 0.55 },
        { id: "y2", w: 3843, x: 700, y: 300, speed: 1.4, wait: 0 },
        //{id:"y2",w:3843,x:-3843,y:300,speed:1.4,wait:0.05},
        { id: "y2", w: 3843, x: -3843, y: 700, speed: 1.4, wait: 0.55 }
    ];
    for (var i = 0; i < cloudData.length; i++) {
        cloudAn(cloudData[i]);
    }
    // var bitmap = new createjs.Bitmap(queue.getResult("ybig"));
    // bitmap.scaleX=bitmap.scaleY=mapWidth/bitmap.image.width;
    //containerZoom.addChild(bitmap);
}


function htmlCityCon(areaCityData, bol) {
    let hreoIconsArr = cityHerosSelf[areaCityData['heroId']];
    let heroStr = '';
    let race = races[areaCityData.raceId];
    for(let i=0;i<hreoIconsArr.length;i++){
        heroStr += `<a href=${hreoIconsArr[i].href}><img class="heroIcon ${switchQuality(hreoIconsArr[i].quality)}" src='${hreoIconsArr[i].icon}'  /></a>`
    }
    var imgStr = '';
    var imgArr = cityTales[areaCityData.id] ;
    var style = "cursor:pointer"
    for(let i=0;i<imgArr.length;i++){
        imgStr += `<img class="pic" id="${imgArr[i].resource_code}" style="${imgArr[i].resource_code.length>0?style:''}" src='${imgArr[i].img}'  />`
    }
    var htmlStr = `<div class='race'>
        <img class="logo" src='${race.raceIcon}'/>
        <span class="title">${areaCityData.cityName}</span>
        </div>
        <div className='heroWrap'>
            ${heroStr}
        </div>
        <p class="textcon">${areaCityData.cityInfo}</p>
        
        <img src=${splitImg} class='splitImg'/>
        <div class='info_imgs'>
        <h5>${"见闻"}</h5>${imgStr}
        </div>`
    $("#con-box").html(htmlStr).delegate('.pic','click',async function (){
        if(this.id.length>0){
            $('.cityInfo_wrap').css('display','block');
            let res = await getCityInfo(this.id);
            if(res.status ===200){
                $(".info_title").html(res.data.data.profile.fields_data.title);
                $("#cityInfo_content").html(res.data.data.profile.fields_data.content)
            }
        }
    })
    // <a class="link" href='https://ac.dragonest.com/zh-cn'>查看更多${race.name}故事</a>
}

function showArea(index) {
    
    $(".pop").css("display", "block");
    var width = w - $(".pop").width() * dpi;
    var line = areaLine[index];
    var scale = 0;
    if (line.w / line.h > width / h) {
        scale = width / line.w / zoom;
    } else {
        scale = h / line.h / zoom;
    }
    if (scale > zoomMax) {
        scale = zoomMax;
    }
    var x = -line.x * zoom * scale + (width - line.w * zoom * scale) / 2;
    var y = -line.y * zoom * scale + (h - line.h * zoom * scale) / 2;
    transformEnd(x, y, scale);
}

function hide() {
    $("#race-hero,#city-other-hero,.pop").css("display", "none");
    $("#city-hero").html("");
    
    containerArea.getChildAt(indexNum*2+1).color = '#fef5e8';
    if(containerLine.getChildAt(curIndex)) containerLine.getChildAt(curIndex).visible = false;
    if (currentTargetCity&& containerArea.getChildAt(indexNum*2).visible) {
        currentTargetCity.getChildAt(3).color = '#fef5e8';
        // resetCityChecked();
        // currentTargetCity = null;
    }

    curIndex='';
}

function incident() {
    window.hammertime = new Hammer(canvas);
    hammertime.get('pinch').set({ enable: true });
    hammertime.on('pinchstart', function (evt) {
        pinchstart.x = evt.center.x * dpi;
        pinchstart.y = evt.center.y * dpi;
    });

    hammertime.on('pinch', function (evt) {
        var scale = evt.scale * mapChangData.scale;
        var x = -((pinchstart.x - mapChangData.x) * evt.scale - pinchstart.x);
        var y = -((pinchstart.y - mapChangData.y) * evt.scale - pinchstart.y);
        transform(x, y, scale);
        hammertime.off('panstart', panstart);
    });

    hammertime.on('pinchend', function (evt) {
        transformEnd(containerAll.x, containerAll.y, containerAll.scaleX, true);
    });
    canvas.addEventListener(start, function () {
        hammertime.on('panstart', panstart);
    }, false);

    canvas.addEventListener(end, function () {
        hammertime.off('panstart', panstart);
    }, false);

    canvas.addEventListener(start, function voicePlay() {
        canvas.removeEventListener(start, voicePlay);
        // heroVoice.play();
    }, false);
    containerMap.addEventListener("mousedown", hide);

    $(".btn-back .logo").on(tap, function (e) {
        e.stopPropagation();
        hide();
        transformEnd(0, -(mapH - h) / 2, 1);
    })
    $(".pop .close").on(tap, function () {
        hide();
    })
}

function goBackFirst(e){
    e.stopPropagation();
    hide();
    transformEnd(0, -(mapH - h) / 2, 1);
}


function startMap(){

    if (show == 1) {
        $(".start").css("display", "none");
        $("#btn-goback").css("display", "block");
        containerArea.visible = true;
        stage.update();
    }
    show = 1;
    incident();
}
var assetsFlag = false;
function loadAssest(data) {
    let loading = document.getElementById("loading");

    $('#startBtn').on(tap, function (e) {
        if(assetsFlag){
            $(".start").css("display", "none");
            $(".btn-back").css("display", "block");
            
            containerArea.visible = true;
            stage.update();
     
        }
    })
    var num = 0;
    let interval = setInterval(function () {
        if(num <30){
         
            loading.style.width = num  + '%';
            num++;
        }

    },20)
    // 统计加载进度
    data.forEach((item)=>{
        queue = new createjs.LoadQueue(true, item.src);
    })

    // 每加载成功一个资源回调一次
    queue.on("fileload", function (event) {
        clearInterval(interval)
        loadCount++;
        loading.style.width =(29 + Number((loadCount * 70 / manifest.length).toFixed(0)))  + '%';
      
    })

    // 所有资源加载完毕后的回调
    queue.on('complete', function () {
        assetsFlag = true;

        $(".lodingBg").css("display","none");
        $(".start").css("display","block");
        startF();
        incident();
        if(cityId) {
            $(".btn-back").css("display", "block");
            var cityItem = cities[cityId]

            let cityIcon = containerCity.getChildByName(cityId)
            currentTargetCity = cityIcon;
            currentTargetCity.getChildAt(3).color = '#ff9701';
            // cityChecked(currentTargetCity)
            cityIconClickHandle(cityItem["areaHtmlIndex"],cityItem,false)
            stage.update();
        }
       
    })

    // 使用preload预加载指定资源
    queue.loadManifest(data);
}

class index extends Component {
    // 初始化页面常量 绑定事件方法
    constructor(props, context) {
        super(props)
        this.state = {
            isActive:true,
            infoStyle:{'display':'none;!important'}
        }
    }
    componentWillMount () {
    }
    componentDidMount() {
        canvas = document.getElementById("demoCanvas");
        screenchange.init();
        canvas.width = w;
        canvas.height = h;
        stage = new createjs.Stage(canvas);
        // wrap = document.getElementById("wrap");
        // heroVoice=document.getElementById("heroVoice");
        stage.enableMouseOver();
        createjs.Touch.enable(stage, false, true);
    }
    toSwitch(e){
        if(this.state.isActive){
            this.setState({'isActive':false});
            infoFlag = false;
            if(curIndex.length == 0) return;
            $("#city-other-hero").css('display',"none")
            $('.city-hero-item').forEach(item=>{
                if($(item).attr('id') != curCity.id) $(item).remove();
            })      
        }else{
    
            this.setState({'isActive':true});
            infoFlag = true;
            if(curIndex.length == 0) return;
            let cityIds = races[curCity.raceId].cityId.split(',');
      
            let otherHreoIcons = areaHeros['area'+curCity.areaHtmlIndex];
            for(let i = 0; i < cityIds.length;i++){
                if(curCity.id ==cityIds[i]) continue;
                let hreoIconsArr = cityHerosSelf[cities[cityIds[i]]['heroId']];
                moreCityHero(hreoIconsArr,$('#city-hero'),cities[cityIds[i]]);
            }
            if(otherHreoIcons) moreCityOtherHero(otherHreoIcons,$('#city-other-hero'))
        }
    }
    render() {
        return (

            <div className="wrap" id="wrap" >
                <div className="lodingBg">
                    <div className="circle"></div>
                    <div className="loadingWrap"><span id="loading"></span></div>
                </div>
                <div className="cityInfo_wrap">
                    <div className='cityInfo'>
                        <img src={closeInfo} className="closeInfo" onClick={()=>{
                                $(".cityInfo_wrap").css('display','none')
                            }}/>
                        <h5 className="info_title"></h5>
                        <img src={cityInfo_split} className="cityinfo_split"/>
                        <div className="cityInfo_content" id="cityInfo_content">
                      
                        </div>
                        
                    </div>
                </div>
                { !cityId &&
                    (<div className="start" >
                        <div className="loadingTemp">
                            <img src="../images/logo.png" />
                            <div className="startBtn" id='startBtn' onTouchStartCapture={(e)=>{startMap(e)}}></div>
                        </div>
                    </div>)                
                }
                <div id="btn-goback" className="btn-back">
                    <a href='https://ac.dragonest.com/zh-cn'><button className="go-office"></button></a>
                    <button className="go-first logo" onTouchStartCapture={(e)=>{goBackFirst(e)}}></button>
                    <div className={this.state.isActive?'switch-wrap active' : 'switch-wrap'} onClick={(e)=>{this.toSwitch(e)}}>
                        <span></span>
                    </div>

                </div>
                <canvas id="demoCanvas"></canvas>
                <div className="more-hero" id="race-hero" style={this.state.infoStyle}></div>
                <div className="city-hero" id="city-hero" style={this.state.infoStyle}></div>
                <div className="city-other-hero" id="city-other-hero" ></div>
                <div ></div>
                <div className="right-box pop">
                    <img src={closeBtn} className="close" />
                    <div className="con-box" id="con-box">
                    </div>
                </div>
            </div>
        )
    }
}

// let index = connect((state) => ({
//   ...state
// }),actions)(index_);

export default index;