import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom';

import index from '../pages/index' 
// import verify from '../pages/verify' 
// import AuthorizedRoute from '../pages/author' 
// import  main from '../pages/main/index' 

// import  {getLoginState} from '../apis/getData' 
// import  {baseURL} from './config'

// const isLogin = async () => {
//   let res_ = await getLoginState(`${baseURL}/auth/verifyQrLogin?code=${sessionStorage.getItem("code")}`)
//     if(res_.errno==200 && res_.data){
//       return true;
//     }
// }


export default () => (
  <Router>
    <Route path="/"  component={index}/>  
    <Route path="/index"  component={index}/>    
    {/* <Route path="/verify"  component={verify}/>     */}
    {/* <AuthorizedRoute path="/main" component={main} /> */}
  </Router>
)
